// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collections-newest-js": () => import("./../../../src/pages/collections/newest.js" /* webpackChunkName: "component---src-pages-collections-newest-js" */),
  "component---src-pages-collections-originals-js": () => import("./../../../src/pages/collections/originals.js" /* webpackChunkName: "component---src-pages-collections-originals-js" */),
  "component---src-pages-collections-popular-js": () => import("./../../../src/pages/collections/popular.js" /* webpackChunkName: "component---src-pages-collections-popular-js" */),
  "component---src-pages-collections-prints-js": () => import("./../../../src/pages/collections/prints.js" /* webpackChunkName: "component---src-pages-collections-prints-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

